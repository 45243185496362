import React, { useEffect, useState } from 'react';
import { Button, Col, Container, Input, Row, Table, Modal, ModalHeader, ModalBody, ModalFooter, Form, FormGroup, Label, Spinner, InputGroup, ListGroupItem, ListGroup } from 'reactstrap';
import axios from 'axios';
import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import '../../../assets/css/ckeditor.css';
import uuid from 'react-uuid';
import { Box } from '@mui/material';

export const AISprints = () => {
    const [searchTerm, setSearchTerm] = useState('');
    const [loading, setLoading] = useState(true);
    const [uploading, setUploading] = useState(false);
    const [originalData, setOriginalData] = useState([]); // State to hold the original data
    const [filteredData, setFilteredData] = useState([]); // State to hold the filtered data
    const [modal, setModal] = useState(false); // State to manage the modal visibility
    const [sprintModal, setSprintModal] = useState(false); // State to manage the modal visibility

    const [update, setUpdate] = useState(false);
    const [series,setSeries] = useState([])
    const [seriesToken,setSeriesToken] = useState([])
    const [sprint,setSprint] = useState({
        id:uuid(),
        title:'',
         date:'',
        description:'',
        documents:[],
        videoUrl:''
       

    })

    const [documentTitle, setDocumentTitle] = useState('');
const [documentUrl, setDocumentUrl] = useState('');

// Function to add document
const handleAddDocument = () => {
    if (!documentTitle || !documentUrl) return; // Validate input

    const newDocument = {
        id: uuid(), // Unique ID for each document
        title: documentTitle,
        url: documentUrl
    };

    setSprint({
        ...sprint,
        documents: [...sprint.documents, newDocument]
    });

    // Clear input fields after adding
    setDocumentTitle('');
    setDocumentUrl('');
};

// Function to remove document by ID
const handleRemoveDocument = (id) => {
    setSprint({
        ...sprint,
        documents: sprint.documents.filter(doc => doc.id !== id)
    });
};

const handleSaveSprint = async () => {
    console.log(sprint)
    try {
        const response = await axios.post('https://hd-chat-api-5203dcc449f5.herokuapp.com/sprints/save-sprint', {seriesId: seriesToken, sprint:sprint});
        console.log('Sprint saved:', response.data);
        setUpdate(!update); // Toggle to refresh data if needed
        toggleSprintModal(); // Close the modal after saving
        toggleModal();
        setUpdate(!update)
    } catch (error) {
        console.error('Error saving sprint:', error);
    }
};

    const [formData, setFormData] = useState({
        id: uuid(),
        bosscallname: '',
        registerLink: '',
        shrmNo: '',
        hrciNo: '',
        documentLinks: '',
        description: '',
        credits: '',
        dateOfCall: '',
        videoLink: ''
    });
    const [isEditing, setIsEditing] = useState(false); // State to track if we are editing an existing Boss Call

    const handleSearch = (e) => {
        const value = e.target.value;
        setSearchTerm(value);
        const filtered = originalData.documents.filter(item =>
            item.bosscallname.toLowerCase().includes(value.toLowerCase())
        );
        setFilteredData({ documents: filtered });
    };

    function formatDate(inputDate) {
        var date = new Date(inputDate);
        if (!isNaN(date.getTime())) {
            // Months use 0 index.
            return date.getMonth() + 1 + '/' + date.getDate() + '/' + date.getFullYear();
        }
      }

    useEffect(() => {
        //https://hd-chat-api-5203dcc449f5.herokuapp.com
        axios.get('https://hd-chat-api-5203dcc449f5.herokuapp.com/sprints/get-sprints')
            .then(response => {
                // handle success
        
                console.log(response.data.sprints);
                setOriginalData(response.data.sprints)
       })
            .catch(error => {
                console.error('Error fetching data:', error);
            }).finally(() => {
                setLoading(false);
            });
    }, [update]);

    const toggleModal = () => {
        setModal(!modal);
    };

    const toggleSprintModal = () => {
        setSprintModal(!sprintModal);
    };



    

    const handleView = (item, token) => {
        console.log(token)
        setSeriesToken(token)
        setSeries(item)
        setModal(true);
    };



    const handleEditSprint = (item)=>{
      
        if(item === null){
            toggleSprintModal()
            setSprint({
                id:uuid(),
                title:'',
                 date:'',
                description:'',
                documents:[],
                videoUrl:''
               
        
            })
        } else {
            setSprint(item)
            toggleSprintModal()
        }
        
    }

    const handleDeleteSprint = async (sprintId) => {
        try {
            const response = await axios.post('https://hd-chat-api-5203dcc449f5.herokuapp.com/sprints/delete-sprint', {
                seriesId: series.id,
                sprintId: sprintId
            });
            console.log('Sprint deleted:', response.data);
            setUpdate(!update); // Toggle to refresh data after deletion
            toggleModal();
        } catch (error) {
            console.error('Error deleting sprint:', error);
        }
    };

    const handleAddSeries = async () => {
        let prmtp = window.prompt('Enter the Series Number ONLY:');
        if(prmtp && prmtp !== ''){
            
        const newSeries = {
            token: uuid(), // Generate a new unique ID
            series: prmtp, // Replace with the actual title or get it from form input
            data: [], // Initialize the data array as empty
        };
        console.log(newSeries)
    
        try {
            const response = await axios.post('https://hd-chat-api-5203dcc449f5.herokuapp.com/sprints/add-series', newSeries);
            console.log('Series added:', response.data);
            setUpdate(!update); // Toggle to refresh data after adding a series
            
        } catch (error) {
            console.error('Error adding series:', error);
        }

    }
    };

    const handleDeleteSeries = async (seriesId) => {
        if (!window.confirm('Are you sure you want to delete this series?')) {
            return;
        }
    
        try {
            const response = await axios.post('https://hd-chat-api-5203dcc449f5.herokuapp.com/sprints/delete-series', { seriesId });
            console.log('Series deleted:', response.data);
            setUpdate(!update); // Toggle to refresh data after deleting a series
        } catch (error) {
            console.error('Error deleting series:', error);
        }
    };

    if (loading) {
        return (<Spinner />);
    } else {
        return (
            <React.Fragment>
                <Container>
                    <Row className="mt-2 mb-2">
                        <Col>
                            <Button color="primary" style={{ width: '100%' }} onClick={handleAddSeries}>+ New Series</Button>
                        </Col>
                        <Col></Col>
                        <Col>
                            <Input
                                placeholder='Search'
                                value={searchTerm}
                                onChange={handleSearch}
                            />
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <Table hover responsive>
                                <thead>
                                    <tr>
                                        <th>Series</th>
                                        {/* <th>Description</th> */}
                                        <th>Sprint Link</th>
                                        <th>Actions</th>
                                       
                                    </tr>
                                </thead>
                                <tbody>
                                    {originalData.map((item, index) => (
                                        <tr key={index}>
                                            <td>Series {item.series}</td>
                                            {/* <td dangerouslySetInnerHTML={{ __html: item.description }} /> */}
                                            <td><a href={`https://app.aiforhrhub.com/#/sprint?ref=${item.token}`} target='_blank' rel='noopener noreferrer'>Link to Sprint</a></td>
                                            <td>
                                                <Button color="warning" onClick={() => handleView(item.data, item.token)}>View Sprints</Button>{' '}
                                                <Button color="danger" onClick={() => handleDeleteSeries(item.token)}>Delete</Button>
                                            </td>
                                        </tr>
                                    ))}
                                </tbody>
                            </Table>
                        </Col>
                    </Row>

                    <Modal size='lg' isOpen={modal} toggle={toggleModal}>
                        <ModalHeader toggle={toggleModal}>{'AI Sprints'}</ModalHeader>
                        <ModalBody>
                            <Box sx={{textAlign:'right'}}><Button onClick={()=>handleEditSprint(null)} style={{backgroundColor:'green'}}>+ Add New Sprint</Button></Box>
                        <Row>
                        <Col>
                            <Table hover responsive>
                                <thead>
                                    <tr>
                                        <th>Title</th>
                                        {/* <th>Description</th> */}
                                        <th>Date</th>
                                        <th>Actions</th>
                                       
                                    </tr>
                                </thead>
                                <tbody>
                                    {series.sort((a, b) => new Date(a.date) - new Date(b.date)).map((item, index) => (
                                        <tr key={index}>
                                            <td>{item.title}</td>
                                            {/* <td dangerouslySetInnerHTML={{ __html: item.description }} /> */}
                                            <td>{formatDate(item.date)}</td>
                                            <td>
                                                <Button color="warning" onClick={() => handleEditSprint(item)}>Edit Sprints</Button>{' '}
                                                <Button color="danger" onClick={() => handleDeleteSprint(item.id)}>Delete</Button>
                                            </td>
                                        </tr>
                                    ))}
                                </tbody>
                            </Table>
                        </Col>
                    </Row>
                                <ModalFooter>
                                    <Button color="secondary" onClick={toggleModal}>Cancel</Button>
                                </ModalFooter>
                   
                        </ModalBody>
                    </Modal>


                    <Modal size='lg' isOpen={sprintModal} toggle={toggleSprintModal}>
                        <ModalHeader toggle={toggleSprintModal}>{'Add New / Edit Sprint'}</ModalHeader>
                        <ModalBody>
                        <Form>
            <FormGroup>
                <Label for="title">Title</Label>
                <Input
                    type="text"
                    id="title"
                    value={sprint.title}
                    onChange={(e) => setSprint({ ...sprint, title: e.target.value })}
                />
            </FormGroup>
            <FormGroup>
                <Label for="date">Date</Label>
                <Input
                    type="date"
                    id="date"
                    value={sprint.date.includes('T') ? sprint.date.split('T')[0]: sprint.date}
                    onChange={(e) => setSprint({ ...sprint, date: e.target.value })}
                />
            </FormGroup>
            <FormGroup>
                <Label for="description">Description</Label>
                <CKEditor
                    editor={ClassicEditor}
                    data={sprint.description}
                    onChange={(event, editor) => {
                        const data = editor.getData();
                        setSprint({ ...sprint, description: data });
                    }}
                />
            </FormGroup>
            <FormGroup>
        <Label for="documentTitle">Document Title</Label>
        <InputGroup>
            <Input
                type="text"
                placeholder="Document Title"
                value={documentTitle}
                onChange={(e) => setDocumentTitle(e.target.value)}
            />
            <Input
                type="url"
                placeholder="Document URL"
                value={documentUrl}
                onChange={(e) => setDocumentUrl(e.target.value)}
            />
            {/* <InputGroupAddon addonType="append"> */}
                <Button color="primary" style={{height:'50px'}} onClick={handleAddDocument}>Add Document</Button>
            {/* </InputGroupAddon> */}
        </InputGroup>
    </FormGroup>

    {ListGroup/* Display list of documents */}
    <ListGroup className='mb-4'>
    <Label for="documents">Documents:</Label>

        {sprint.documents.map((doc) => (
            <ListGroupItem key={doc.id}>
                <a href={doc.url} target="_blank" rel="noopener noreferrer">{doc.title}</a>
               
                <Button style={{marginLeft:10, padding:3}} color="primary" onClick={() => handleRemoveDocument(doc.id)}>Delete</Button>
            </ListGroupItem>
        ))}
    </ListGroup>
            <FormGroup>
                <Label for="videoUrl">Video URL</Label>
                <Input
                    type="url"
                    id="videoUrl"
                    value={sprint.videoUrl}
                    onChange={(e) => setSprint({ ...sprint, videoUrl: e.target.value })}
                />
            </FormGroup>
        </Form>

        <ModalFooter>
        <Button color="secondary" onClick={toggleSprintModal}>Cancel</Button>
        <Button color="primary" onClick={handleSaveSprint}>Save Changes</Button>
    </ModalFooter>
                   
                        </ModalBody>
                    </Modal>
                </Container>
            </React.Fragment>
        );
    }
};

export default AISprints;
