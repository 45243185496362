import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import {
  Container,
  Row,
  Col,
  Card,
  CardBody,
  Button,
  Label,
  Input,
  Form,
  FormFeedback,
} from "reactstrap";
import andereHrLogo from '../../assets/images/AndereHRLogo-light.png';
// Import Home Button
import AccountHomeButton from "../Auth/account-home-button";
import { AdminCards } from "./AdminCards";
import { Divider, ListItemText, Menu, MenuItem, Paper, Typography } from "@mui/material";
import { FormsAndDocuments } from "./Apps/FormsAndDocuments";
import { PoliciesAndProcedures } from "./Apps/PoliciesAndProcedures";
import BossCalls from "./Apps/BossCalls";
import { StoreApp } from "./Apps/StoreApp";
import AISprints from "./Apps/AISprints";

const AdminHome = () => {
  const navigate = useNavigate();
  const [app, setApp] = useState(<BossCalls />);
  const [title, setTitle] = useState('Boss Calls');
  const [anchorQmEl, setAnchorQmEl] = useState(null);

  useEffect(() => {
    const token = localStorage.getItem('authToken');
    if (!token) {
      navigate('/');
    }
    document.body.classList.add("bg-account-pages");
    document.body.classList.add("py-4");
    document.body.classList.add("py-sm-0");
    document.getElementById("colorTheme").setAttribute("href","assets/colors/red.css")

    // Define the cleanup function to remove the added classes
    return () => {
      document.body.classList.remove("bg-account-pages");
      document.body.classList.remove("py-4");
      document.body.classList.remove("py-sm-0");
      document.getElementById("colorTheme").setAttribute("href","assets/colors/cyan.css")
    };
  }, [navigate]);

  const toggleThem = () => {
    if (document.body.getAttribute("data-bs-theme") === "light") {
      document.body.setAttribute("data-bs-theme", "dark");
    } else {
      document.body.setAttribute("data-bs-theme", "light");
    }
  };

  const handleQuickMenuOpen = (event) => {
    setAnchorQmEl(event.currentTarget);
  }
  const handleQuickMenuClose = () => {
    setAnchorQmEl(null);
  };

  const handleLogout = () => {
    localStorage.removeItem('authToken');
    navigate('/');
  };

  const adminFunctions = [
    {
        id: 0,
        name: 'Forms and Documents',
        link: () => { setApp(<FormsAndDocuments />); setTitle('Forms and Documents'); handleQuickMenuClose(); }
    },
    {
        id: 1,
        name: 'Policies and Procedures',
        link: () => { setApp(<PoliciesAndProcedures />); setTitle('Policies and Procedures'); handleQuickMenuClose(); }
    },
    {
        id: 2,
        name: 'Boss Calls',
        link: () => { setApp(<BossCalls />); setTitle('Boss Calls'); handleQuickMenuClose(); }
    },
    {
        id: 3,
        name: 'Store **Coming Soon**',
        link: () => { setApp(<StoreApp />); setTitle('Store'); handleQuickMenuClose(); }
    },
    {
      id: 4,
      name: 'AI Sprints',
      link: () => { setApp(<AISprints />); setTitle('AI Sprints'); handleQuickMenuClose(); }
  },
  ];

  return (
    <React.Fragment>
      <h3 style={{ paddingLeft: 20, paddingTop: 20 }} className="font-weight-bold">
        <Link to="/" className="text-dark text-uppercase account-pages-logo">
          <img src={andereHrLogo} width="200px" height="auto" alt="Logo" />
        </Link>
      </h3>
      <AccountHomeButton />

      <Link to="#" id="mode" className="mode-btn text-white" onClick={toggleThem}>
        <i className="mdi mdi-weather-sunny bx-spin mode-light"></i>
        <i className="mdi mdi-moon-waning-crescent mode-dark"></i>
      </Link>

      <section className="vh-100">
        <div className="display-table">
          <div className="mt-5">
            <Container>
              <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginBottom: '20px' }}>
                <Button onClick={handleQuickMenuOpen} variant='contained' sx={{ color: 'white', borderColor: 'white', backgroundColor: '#FCBD2A' }}>
                  <b>Menu &#8681;</b>
                </Button>
                <Button onClick={handleLogout} variant='contained' sx={{ color: 'white', borderColor: 'white', backgroundColor: '#FCBD2A' }}>
                  <b>Logout</b>
                </Button>
              </div>
              <Menu
                id="profile-menu"
                anchorEl={anchorQmEl}
                open={Boolean(anchorQmEl)}
                onClose={handleQuickMenuClose} // Close the menu on selection or outside click
              >
                {adminFunctions.map((i) => (
                  <AdminCards
                    name={i.name}
                    link={i.link}
                    id={i.id}
                    key={i.id}
                  />
                ))}
              </Menu>

              <h1 className="get-started-title text-white">{title}</h1>
              <Row className="justify-content-center">
                <Paper>
                  {app}
                </Paper>
              </Row>
            </Container>
          </div>
        </div>
      </section>
    </React.Fragment>
  );
}

export default AdminHome;
