import React, { useEffect, useState } from 'react';
import { Button, Col, Container, Input, Row, Table, Modal, ModalHeader, ModalBody, ModalFooter, Form, FormGroup, Label, Spinner } from 'reactstrap';
import axios from 'axios';
import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import '../../../assets/css/ckeditor.css';
import uuid from 'react-uuid';

export const BossCalls = () => {
    const [searchTerm, setSearchTerm] = useState('');
    const [loading, setLoading] = useState(true);
    const [uploading, setUploading] = useState(false);
    const [originalData, setOriginalData] = useState({ documents: [] }); // State to hold the original data
    const [filteredData, setFilteredData] = useState({ documents: [] }); // State to hold the filtered data
    const [modal, setModal] = useState(false); // State to manage the modal visibility
    const [update, setUpdate] = useState(false);
    const [formData, setFormData] = useState({
        id: uuid(),
        bosscallname: '',
        registerLink: '',
        shrmNo: '',
        hrciNo: '',
        documentLinks: '',
        description: '',
        credits: '',
        dateOfCall: '',
        videoLink: ''
    });
    const [isEditing, setIsEditing] = useState(false); // State to track if we are editing an existing Boss Call

    const handleSearch = (e) => {
        const value = e.target.value;
        setSearchTerm(value);
        const filtered = originalData.documents.filter(item =>
            item.bosscallname.toLowerCase().includes(value.toLowerCase())
        );
        setFilteredData({ documents: filtered });
    };

    useEffect(() => {
        axios.get('https://hdhr-apps-api.herokuapp.com/admin/get-bosscalls')
            .then(response => {
                // handle success
                const documents = response.data.documents;
                console.log(documents);
                setOriginalData(documents); // Set the original data
                setFilteredData(documents); // Initially, set the filtered data to the original data
            })
            .catch(error => {
                console.error('Error fetching data:', error);
            }).finally(() => {
                setLoading(false);
            });
    }, [update]);

    const toggleModal = () => {
        setModal(!modal);
    };

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData({
            ...formData,
            [name]: value
        });
    };

    const handleCKEditorChange = (event, editor) => {
        const data = editor.getData();
        setFormData({
            ...formData,
            description: data
        });
    };

    const handleSubmit = async (e) => {
        setUploading(true);
        e.preventDefault();
        try {
            let response;
            if (isEditing) {
                response = await axios.put('https://hdhr-apps-api.herokuapp.com/admin/update-bosscall', formData);
            } else {
                response = await axios.post('https://hdhr-apps-api.herokuapp.com/admin/add-bosscall', formData);
            }

            const result = response.data;

            if (result.success) {
                alert(`Boss Call ${isEditing ? 'updated' : 'added'} successfully`);
                setModal(false);
                setUpdate(!update);
                setUploading(false);
            } else {
                alert(`Failed to ${isEditing ? 'update' : 'add'} Boss Call`);
            }
        } catch (error) {
            console.error(`Error ${isEditing ? 'updating' : 'adding'} Boss Call:`, error);
            alert(`An error occurred while ${isEditing ? 'updating' : 'adding'} the Boss Call`);
            setUploading(false);
        }
    };

    const handleEdit = (item) => {
        setFormData(isEditing?{
            id:item.id,
            bosscallname: item.bosscallname,
            registerLink: item.registerLink,
            shrmNo: item.shrmNo,
            hrciNo: item.hrciNo,
            documentLinks: item.documentLinks,
            description: item.description,
            credits: item.credits,
            dateOfCall: item.dateOfCall,
            videoLink: item.videoLink
        }: {
            id: item.id,
            bosscallname: item.bosscallname,
            registerLink: item.registerLink,
            shrmNo: item.shrmNo,
            hrciNo: item.hrciNo,
            documentLinks: item.documentLinks,
            description: item.description,
            credits: item.credits,
            dateOfCall: item.dateOfCall,
            videoLink: item.videoLink});
        setIsEditing(true);
        setModal(true);
    };

    const handleDelete = async (id) => {
        if (!window.confirm('Are you sure you want to delete this Boss Call?')) {
            return;
        }
//https://hdhr-apps-api.herokuapp.com
        try {
            const response = await axios.delete('https://hdhr-apps-api.herokuapp.com/admin/delete-bosscall', { data: { id } });
            if (response.data.success) {
                alert('Boss Call deleted successfully');
                // Update the state to remove the deleted Boss Call
                const updatedDocuments = originalData.documents.filter(doc => doc.id !== id);
                setOriginalData({ documents: updatedDocuments });
                setFilteredData({ documents: updatedDocuments });
            } else {
                alert('Failed to delete Boss Call');
            }
        } catch (error) {
            console.error('Error deleting Boss Call:', error);
            alert('An error occurred while deleting the Boss Call');
        }
    };

    if (loading) {
        return (<Spinner />);
    } else {
        return (
            <React.Fragment>
                <Container>
                    <Row className="mt-2 mb-2">
                        <Col>
                            <Button color="primary" style={{ width: '100%' }} onClick={() => {
                                setIsEditing(false);
                                setFormData({
                                    id:uuid(),
                                    bosscallname: '',
                                    registerLink: '',
                                    shrmNo: '',
                                    hrciNo: '',
                                    documentLinks: '',
                                    description: '',
                                    credits: '',
                                    dateOfCall: '',
                                    videoLink: ''
                                });
                                toggleModal();
                            }}>+ New</Button>
                        </Col>
                        <Col></Col>
                        <Col>
                            <Input
                                placeholder='Search'
                                value={searchTerm}
                                onChange={handleSearch}
                            />
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <Table hover responsive>
                                <thead>
                                    <tr>
                                        <th>Boss Call Name</th>
                                        {/* <th>Description</th> */}
                                        <th>Register Link</th>
                                        <th>SHRM No</th>
                                        <th>HRCI No</th>
                                        <th>Date of Call</th>
                                        <th>Actions</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {filteredData.documents.sort((a, b) => new Date(b.dateOfCall) - new Date(a.dateOfCall)).map((item, index) => (
                                        <tr key={index}>
                                            <td>{item.bosscallname}</td>
                                            {/* <td dangerouslySetInnerHTML={{ __html: item.description }} /> */}
                                            <td><a href={item.registerLink} target='_blank' rel='noopener noreferrer'>Register</a></td>
                                            <td>{item.shrmNo}</td>
                                            <td>{item.hrciNo}</td>
                                            <td>{item.dateOfCall}</td>
                                            <td>
                                                <Button color="warning" onClick={() => handleEdit(item)}>Edit</Button>{' '}
                                                <Button color="danger" onClick={() => handleDelete(item.id)}>Delete</Button>
                                            </td>
                                        </tr>
                                    ))}
                                </tbody>
                            </Table>
                        </Col>
                    </Row>

                    <Modal size='lg' isOpen={modal} toggle={toggleModal}>
                        <ModalHeader toggle={toggleModal}>{isEditing ? 'Edit Boss Call' : 'Add New Boss Call'}</ModalHeader>
                        <ModalBody>
                            <Form onSubmit={handleSubmit}>
                                <FormGroup>
                                    <Label for="bosscallname">Boss Call Name</Label>
                                    <Input
                                        type="text"
                                        name="bosscallname"
                                        id="bosscallname"
                                        value={formData.bosscallname}
                                        onChange={handleChange}
                                        required
                                    />
                                </FormGroup>
                                <FormGroup>
                                    <Label for="registerLink">Register Link</Label>
                                    <Input
                                        type="text"
                                        name="registerLink"
                                        id="registerLink"
                                        value={formData.registerLink}
                                        onChange={handleChange}
                                        required
                                    />
                                </FormGroup>
                                <FormGroup>
                                    <Label for="shrmNo">SHRM No</Label>
                                    <Input
                                        type="text"
                                        name="shrmNo"
                                        id="shrmNo"
                                        value={formData.shrmNo}
                                        onChange={handleChange}
                                        required
                                    />
                                </FormGroup>
                                <FormGroup>
                                    <Label for="hrciNo">HRCI No</Label>
                                    <Input
                                        type="text"
                                        name="hrciNo"
                                        id="hrciNo"
                                        value={formData.hrciNo}
                                        onChange={handleChange}
                                        required
                                    />
                                </FormGroup>
                                <FormGroup>
                                    <Label for="documentLinks">Document Links</Label>
                                    <Input
                                        type="text"
                                        name="documentLinks"
                                        id="documentLinks"
                                        value={formData.documentLinks}
                                        onChange={handleChange}
                                    />
                                </FormGroup>
                                <FormGroup>
                                    <Label for="description">Description</Label>
                                    <CKEditor
                                        editor={ClassicEditor}
                                        data={formData.description}
                                        onChange={handleCKEditorChange}
                                    />
                                </FormGroup>
                                <FormGroup>
                                    <Label for="credits">Credits</Label>
                                    <Input
                                        type="text"
                                        name="credits"
                                        id="credits"
                                        value={formData.credits}
                                        onChange={handleChange}
                                        required
                                    />
                                </FormGroup>
                                <FormGroup>
                                    <Label for="dateOfCall">Date of Call</Label>
                                    <Input
                                        type="date"
                                        name="dateOfCall"
                                        id="dateOfCall"
                                        value={formData.dateOfCall}
                                        onChange={handleChange}
                                        required
                                    />
                                </FormGroup>
                                <FormGroup>
                                    <Label for="videoLink">Video Link</Label>
                                    <Input
                                        type="text"
                                        name="videoLink"
                                        id="videoLink"
                                        value={formData.videoLink}
                                        onChange={handleChange}
                                    />
                                </FormGroup>
                                <ModalFooter>
                                    <Button color="primary" type="submit">{isEditing ? 'Update' : 'Submit'} <Spinner size="sm" style={{ display: uploading ? 'inline-block' : 'none' }} /></Button>
                                    <Button color="secondary" onClick={toggleModal}>Cancel</Button>
                                </ModalFooter>
                            </Form>
                        </ModalBody>
                    </Modal>
                </Container>
            </React.Fragment>
        );
    }
};

export default BossCalls;
